import React from "react";
import { Expert } from "../components/Expert.component";

export const ResumeSkill = ({ props }) => {
  const totalPoints = props.expertLevel / 25;

  return (
    <ul className="expert-levels">
      <li>
        <Expert
          expertLevel={totalPoints > 1 ? "100" : props.expertLevel}
          totalExpert={props.totalExpert}
          barHeight={props.barHeight}
        />
      </li>
      <li>
        <Expert
          expertLevel={
            totalPoints >= 2 ? "100" : totalPoints > 1 ? props.expertLevel : "0"
          }
          totalExpert={props.totalExpert}
          barHeight={props.barHeight}
        />
      </li>
      <li>
        <Expert
          expertLevel={
            totalPoints >= 3 ? "100" : totalPoints > 2 ? props.expertLevel : "0"
          }
          totalExpert={props.totalExpert}
          barHeight={props.barHeight}
        />
      </li>
      <li>
        <Expert
          expertLevel={
            totalPoints === 4
              ? "100"
              : totalPoints > 3
              ? props.expertLevel
              : "0"
          }
          totalExpert={props.totalExpert}
          barHeight={props.barHeight}
        />
      </li>
    </ul>
  );
};
