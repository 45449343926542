import React from "react";
import { Expert } from "./Expert.component";
import { ResumeSkill } from "./ResumeSkill.component";

export const SkillSet = (props) => {
  return (
    <>
      <li className="skill">
        {props.children}
        {!props.resume && <SingleBarExpert props={props} />}
        {props.resume && <ResumeSkill props={props} />}
      </li>
    </>
  );
};

const SingleBarExpert = ({ props }) => {
  return (
    <Expert
      expertLevel={props.expertLevel}
      totalExpert={props.totalExpert}
      barHeight={props.barHeight}
    />
  );
};
