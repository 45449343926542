import React, { useEffect } from "react";
import resumeData from "../assets/myresume.json";
import { ContactIcons } from "./ContactIcons.component";
import { ReactComponent as Java } from "../assets/skills/java.svg";
import { ReactComponent as Kafka } from "../assets/skills/kafka.svg";
import { SkillSet } from "./SkillSet.component";
import Button from "react-bootstrap/Button";
import { ReactComponent as Kubernetes } from "../assets/skills/kubernetes.svg";
import { ReactComponent as CSS } from "../assets/skills/css3.svg";
import { ReactComponent as JS } from "../assets/skills/javascript.svg";
import { ReactComponent as REACT } from "../assets/skills/react.svg";
import { ReactComponent as SASS } from "../assets/skills/sass.svg";
import { ReactComponent as SpringBoot } from "../assets/skills/spring.svg";
import { ReactComponent as HTML } from "../assets/skills/html-5.svg";
import { ReactComponent as Docker } from "../assets/skills/docker.svg";
import { ReactComponent as SpringReactive } from "../assets/skills/spring-reactive.svg";
import { Timeline } from "./Timeline.component";
import { ProjectSummary } from "./ProjectSummary.component";

export const Resume = ({ setResume }) => {
  useEffect(() => {
    setResume(true);
    document.body.style.background = "white";
  }, [setResume]);

  const ref = React.createRef();

  return (
    <div className="resume__container" ref={ref}>
      {/* This section is about resume top */}

      <div className="resume__top">
        <div className="resume__me__container">
          <div className="resume__me__left">
            <ContactIcons contacts={resumeData.subContacts}/>
          </div>
          <div className="resume__me__middle">
            <div className="key__highlights">
              {resumeData.keyHighlights && <ContactIcons contacts={resumeData.keyHighlights}/>}
            </div>
          </div>

          <div className="resume__me__right">
            <h3 className="text-data">{resumeData.name}</h3>
            <h4 className="text-data">{resumeData.jobTitle}</h4>

            <div className="social__links">
              <ContactIcons contacts={resumeData.socialLinks}/>
            </div>
          </div>
        </div>
      </div>

      <div className="resume__main">
        <div className="resume__left">
          {/*Profile */}
          <div className="profile">
            <div className="profile__image__container"></div>
            {/*
                    <div className="profile__contacts__title">
                      <h4 className="text-data">Contacts</h4>
                    </div>
          
                    <div className="profile__contacts__container">
                      <div className="profile__contacts">
                        <ContactIcons contacts={resumeData.mainContacts} />
                      </div>
                    </div>
                    */}
          </div>
          {/*Skills*/}
          <div className="resume__skills_container">
            <div className="resume__skills__title">
              <h4 className="text-data">Key Skills</h4>
            </div>
            <ul className="resume__skills">
              <li key="java">
                <SkillSet
                    resume={true}
                    expertLevel="85"
                    totalExpert="5"
                    barHeight="10"
                >
                  <Java className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="springboot">
                <SkillSet
                    resume={true}
                    expertLevel="85"
                    totalExpert="5"
                    barHeight="10"
                >
                  <SpringBoot className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="springreactive">
                <SkillSet
                    resume={true}
                    expertLevel="70"
                    totalExpert="5"
                    barHeight="10"
                >
                  <SpringReactive className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="kafka">
                <SkillSet
                    resume={true}
                    expertLevel="75"
                    totalExpert="5"
                    barHeight="10"
                >
                  <Kafka className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="js">
                <SkillSet
                    resume={true}
                    expertLevel="70"
                    totalExpert="5"
                    barHeight="10"
                >
                  <JS className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="react">
                <SkillSet
                    resume={true}
                    expertLevel="70"
                    totalExpert="5"
                    barHeight="10"
                >
                  <REACT className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="css">
                <SkillSet
                    resume={true}
                    expertLevel="60"
                    totalExpert="5"
                    barHeight="10"
                >
                  <CSS className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="sass">
                <SkillSet
                    resume={true}
                    expertLevel="60"
                    totalExpert="5"
                    barHeight="10"
                >
                  <SASS className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="html">
                <SkillSet
                    resume={true}
                    expertLevel="90"
                    totalExpert="5"
                    barHeight="10"
                >
                  <HTML className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="kubernetes">
                <SkillSet
                    resume={true}
                    expertLevel="50"
                    totalExpert="5"
                    barHeight="10"
                >
                  <Kubernetes className="resume__skill__image"/>
                </SkillSet>
              </li>
              <li key="docker">
                <SkillSet
                    resume={true}
                    expertLevel="50"
                    totalExpert="5"
                    barHeight="10"
                >
                  <Docker className="resume__skill__image"/>
                </SkillSet>
              </li>
            </ul>
          </div>
          {/*Other Skills*/}
          <div className="other__skills__container">
            <div className="other__skills__title">
              <h4 className="text-data">Other Skills and Tools</h4>
            </div>
            <div className="other__skills__tools">
              <div className="first">
                {resumeData.otherSkills.first.map((skill) => (
                  <Button className="other__skill__first other__skill text-data">
                    {`#${skill}`}
                  </Button>
                ))}
              </div>
              <div className="second">
                {resumeData.otherSkills.second.map((skill) => (
                  <Button className="other__skill__second other__skill text-data">
                    {`#${skill}`}
                  </Button>
                ))}
              </div>
              <div className="third">
                {resumeData.otherSkills.third.map((skill) => (
                  <Button className="other__skill__third other__skill text-data">
                    {`#${skill}`}
                  </Button>
                ))}
              </div>
            </div>
          </div>

          {/* Achievements */}
          <div className="achievements__container">
            <div className="achievements__title">
              <h4 className="text-data">Key Achievments</h4>
            </div>
            <ul className="achievements">
              {resumeData.achievements.map((val) => {
                return (
                    <li key={val}>
                      <p className="text-data">{val}</p>
                    </li>
                );
              })}
            </ul>
          </div>

        </div>
        <div className="resume__right">
          <div className="summary">
            <div className="summary__title">
              <h4 className="text-data">Professional Summary :</h4>
            </div>
            <ul className="professional__summary__container">
              {resumeData.professionalSummary.map((prof) => {
                return (
                  <li key={prof}>
                    <p className="text-data">{prof}</p>
                  </li>
                );
              })}
            </ul>
          </div>

          {/*Professional Experience*/}
          <div className="professional__experience__container">
            <div className="professional__experience__title">
              <h4 className="text-data">Professional Experience</h4>
            </div>
            <ul className="timeline">
              {resumeData.professionalExperience.map((project) => {
                return (
                  <li key={project.id}>
                    <div className="project__detials__container">
                      <Timeline />
                      <ProjectSummary project={project} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>



          {/* Education */}
          <div className="education__container">
            <div className="education__title">
              <h4 className="text-data">Education</h4>
            </div>
            <div className="education">
              <ul className="timeline">
                {resumeData.education.map((qual) => {
                  return (
                    <li key="degree">
                      <div className="degree__container">
                        <h4 className="text-data">{qual.degree}</h4>
                        <div className="education__centre">
                          <h5 className="text-data education">
                            {qual.university}
                          </h5>
                          <h5 className="text-data education">
                            {qual.duration}
                          </h5>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
