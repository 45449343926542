import React, { useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

export const ResumeHeader = ({ show, setShow, setResume }) => {
  const history = useHistory();
  const [navBarBackgroundDisplay, setNavBarBackgroundDisplay] = useState(false);

  return (
    <div className="resume__header__container">
      <div
        className={`navbar-items ${
          navBarBackgroundDisplay && "nav_background"
        }`}
      >
        <ul className="nav-items">
          <li className="nav-item" activeclass="active">
            <Link
              to="/"
              onClick={() => {
                setResume(false);
                history.push("/");
                setNavBarBackgroundDisplay(false);
              }}
            >
              <div className="nav-item-container">
                <AiOutlineHome className="nav-item-icon" />
                <p className="nav-item-icon-name">Home</p>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="https://drive.google.com/file/d/1b7BXeORXjVDzK4-VfRCTETWFICqjEyVG/view?usp=sharing"
              target="_blank"
              download
              onClick={() => {
                setNavBarBackgroundDisplay(true);
                !show && setShow(true);
              }}
            >
              <div className="nav-item-container">
                <a
                  href="https://drive.google.com/file/d/1b7BXeORXjVDzK4-VfRCTETWFICqjEyVG/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  <IoMdDownload className="nav-item-icon" />
                  <p className="nav-item-icon-name">Download</p>
                </a>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
