import React, { useState, useEffect } from "react";
import { getBlogCollection } from "../utils/helper";
import { usePostsValue } from "../context";
import { AllBlogs } from "./AllBlogs.component";
import Button from "react-bootstrap/Button";

export const BlogCollections = ({ setShow }) => {
  const [collection, setCollection] = useState([]);
  const [currentCollection, setCurrentCollection] = useState("");
  const [clicked, setClicked] = useState(false);
  const { posts } = usePostsValue();

  useEffect(() => {
    setCollection(getBlogCollection(posts));
  }, [posts]);

  return (
    <div id="blog" className="blogs-container">
      <div className="blogs-intro">
        <h1 className="name">
          <span>B</span>logs
        </h1>
        <h4 className="blogs-summary">
          Here are the list of blogs that I have written
        </h4>
      </div>
      {!clicked && (
        <ul className="all-coll-blogs">
          {collection.map((blogCollection) => (
            <li
              key={blogCollection}
              className="blog-collection-container"
              onClick={() => {
                setCurrentCollection(blogCollection);
                setClicked(true);
              }}
            >
              {blogCollection}
            </li>
          ))}
        </ul>
      )}

      {clicked && (
        <>
          <Button
            className="all-topics-button"
            onClick={() => setClicked(false)}
          >
            All Topics
          </Button>
          <AllBlogs
            currentCollection={currentCollection}
            posts={posts}
            setShow={setShow}
          />
        </>
      )}
    </div>
  );
};
