import React from "react";
import { ReactComponent as NetflixFront } from "../assets/projects/NetflixFront.svg";
import { ReactComponent as NetflixBack } from "../assets/projects/NetflixBack.svg";
import { ReactComponent as EcommerceFront } from "../assets/projects/ecommerceFront.svg";
import { ReactComponent as EcommerceBack } from "../assets/projects/ecommerceBack.svg";
import { ReactComponent as TodoistFront } from "../assets/projects/TodoistFront.svg";
import { ReactComponent as TodoistBack } from "../assets/projects/TodoistBack.svg";
import { ReactComponent as SimpleTodoFront } from "../assets/projects/SimpleTodoFront.svg";
import { ReactComponent as SimpleTodoBack } from "../assets/projects/SimpleTodoBack.svg";
import NetflixCover from "../assets/projects/NetflixOverview.png";
import SimpleToDoCover from "../assets/projects/SimpleToDoAppOverview.png";
import TodoistCover from "../assets/projects/TodoistOverview.png";
import EcommerceCover from "../assets/projects/ecommerceOverview.png";

import { IndividualProject } from "./IndividualProject.component";

export const AllProjects = () => {
  return (
    <div id="projects" className="projects-container">
      <div className="projects-intro">
        <h1 className="name">
          <span>M</span>y
        </h1>
        <h1 className="name">
          <span>P</span>rojects
        </h1>

        <h4 className="projects-summary">
          I have worked on number of projects throughout,but many of them cannot
          be shared here due to conflidentiality with client.But I try to spend
          lot of additional time in doing some side projects for learning and
          enhancing my skills.Here are some of the key side projects that I have
          built
        </h4>
      </div>

      <ul className="projects">
        <IndividualProject
          projectDesc="Netflix"
          front={<NetflixFront className="project" />}
          back={<NetflixBack className="project" />}
          overview={
            <img
              className="project-overview-image"
              src={NetflixCover}
              alt="Netflix"
            />
          }
        />

        <IndividualProject
          projectDesc="Ecommerce"
          front={<EcommerceFront className="project" />}
          back={<EcommerceBack className="project" />}
          overview={
            <img
              className="project-overview-image"
              src={EcommerceCover}
              alt="CrownClothing"
            />
          }
        />

        <IndividualProject
          projectDesc="Todoist"
          front={<TodoistFront className="project" />}
          back={<TodoistBack className="project" />}
          overview={
            <img
              className="project-overview-image"
              src={TodoistCover}
              alt="Todoist"
            />
          }
        />

        <IndividualProject
          projectDesc="SimpleToDo"
          front={<SimpleTodoFront className="project large" />}
          back={<SimpleTodoBack className="project large" />}
          overview={
            <img
              className="project-overview-image"
              src={SimpleToDoCover}
              alt="SimpleToDo"
            />
          }
        />
      </ul>
    </div>
  );
};
