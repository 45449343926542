import "./App.css";
import React, { Suspense } from "react";
import { HomePage } from "./components/HomePage.component";
import { About } from "./components/About.component";
import { AllProjects } from "./components/AllProjects.component";
import { Social } from "./components/Social.component";
import { BlogCollections } from "./components/BlogCollections.component";
import { Navbar } from "./components/Navbar.component";
import { Skills } from "./components/Skills.component";
import { PostsProvider, PostProvider, LoadingProvider } from "./context";
import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { IndividualBlog } from "./components/IndividualBlog.component";
import { Resume } from "./components/Resume.component";
import Spinner from "../src/components/spinner/spinner.component";
import { ResumeHeader } from "./components/ResumeHeader.component";

function App() {
  const [show, setShow] = useState(true);
  const [resume, setResume] = useState(false);
  const [navBarBackgroundDisplay, setNavBarBackgroundDisplay] = useState(false);

  return (
    <Router>
      <div className="App">
        <PostsProvider>
          <PostProvider>
            <LoadingProvider>
              <Suspense fallback={<Spinner />}>
                {!resume && (
                  <Navbar
                    show={show}
                    setShow={setShow}
                    setResume={setResume}
                    navBarBackgroundDisplay={navBarBackgroundDisplay}
                    setNavBarBackgroundDisplay={setNavBarBackgroundDisplay}
                  />
                )}
                {resume && (
                  <ResumeHeader
                    show={show}
                    setShow={setShow}
                    setResume={setResume}
                  />
                )}
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/skills" component={Skills} />
                  <Route exact path="/projects" component={AllProjects} />
                  <Route
                    exact
                    path="/blog"
                    render={() => <BlogCollections setShow={setShow} />}
                  />
                  <Route
                    exact
                    path="/blog/:blogId"
                    render={() => (
                      <IndividualBlog
                        setNavBarBackgroundDisplay={setNavBarBackgroundDisplay}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/my-resume"
                    render={() => <Resume setResume={setResume} />}
                  />
                </Switch>

                {/* {show && (
                <>
                  <About />
                  <Skills />
                  <AllProjects />
                  <BlogCollections setShow={setShow} />
                </>
              )} */}

                {!resume && <Social />}
              </Suspense>
            </LoadingProvider>
          </PostProvider>
        </PostsProvider>
      </div>
    </Router>
  );
}

export default App;
