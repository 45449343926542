const projects = [
  {
    id: 1,
    name: "Netflix",
    summary:
      " This project is a clone of Netflix front end.It pulls data from IMDB database to show movie details",
    techStack: ["html-5", "css3", "javascript", "react", "firebase"],
  },
  {
    id: 2,
    name: "Ecommerce",
    summary:
      " This project is a an Ecommerce app which presents static data with various categories of stock for sale.It contains basic functionalities to browse,select,add items to cart and perform a sale.This app is integrated with payments using Stripe API and state is managed in Redux",
    techStack: ["html-5", "css3", "javascript", "react", "redux"],
  },
  {
    id: 3,
    name: "Todoist",
    summary:
      "This project is a clone of todoist app.It currently contains features to add project,add tasks and manage them (data persisted in firestore). More functionalities to be added in pipeline.States are managed using React Hooks",
    techStack: ["html-5", "css3", "javascript", "react", "firebase"],
  },
  {
    id: 4,
    name: "SimpleToDo",
    summary:
      "Simple Todo App, contains features to maintain todos.Tasks are persisted in Local storage",
    techStack: ["html-5", "css3", "javascript", "react"],
  },
];

const blogs = [
  {
    id: 1,
    name: "Topic 1",
    shortSummary: "Topic 1 Short Summary",
    link: "",
  },
  {
    id: 2,
    name: "Topic 2",
    shortSummary: "Topic 2 Short Summary",
    link: "",
  },
  {
    id: 3,
    name: "Topic 3",
    shortSummary: "Topic 3 Short Summary",
    link: "",
  },
  {
    id: 4,
    name: "Topic 4",
    shortSummary: "Topic 4 Short Summary",
    link: "",
  },
  {
    id: 5,
    name: "Topic 5",
    shortSummary: "Topic 5 Short Summary",
    link: "",
  },
  {
    id: 6,
    name: "Topic 6",
    shortSummary: "Topic 6 Short Summary",
    link: "",
  },
];

export const getRandomQuote = (response) => {
  return response.data + "..";
};

export const getProject = (projectName) => {
  if (projectName === undefined) return;

  return projects.find(
    (project) => project.name.toLowerCase() === projectName.toLowerCase()
  );
};

export const getBlogs = () => {
  return blogs;
};

export const getBlogCollection = (blogs) => {
  if (blogs === undefined || blogs.length === 0) return [];

  const allCollections = blogs.data
    .filter((blog) => !!blog.fields.category)
    .map((blogData) => blogData.fields.category);

  return [...new Set(allCollections.flat(1))];
};

export const getBlogByCollection = (currentCollection, posts) => {
  if (posts === undefined || posts.length === 0) return [];

  return posts.data
    .filter((post) => !!post.fields.category)
    .filter((postData) => postData.fields.category.includes(currentCollection));
};
