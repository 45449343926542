import React from "react";
import { SkillSet } from "./SkillSet.component";
import { ReactComponent as Java } from "../assets/skills/java.svg";
import { ReactComponent as Aws } from "../assets/skills/aws.svg";
import { ReactComponent as Kubernetes } from "../assets/skills/kubernetes.svg";
import { ReactComponent as Azure } from "../assets/skills/azure.svg";
import { ReactComponent as CSS } from "../assets/skills/css3.svg";
import { ReactComponent as JS } from "../assets/skills/javascript.svg";
import { ReactComponent as REACT } from "../assets/skills/react.svg";
import { ReactComponent as SASS } from "../assets/skills/sass.svg";
import { ReactComponent as SpringBoot } from "../assets/skills/spring.svg";
import { ReactComponent as HTML } from "../assets/skills/html-5.svg";
import { ReactComponent as Docker } from "../assets/skills/docker.svg";
import { ReactComponent as SpringReactive } from "../assets/skills/spring-reactive.svg";

export const Skills = () => {
  return (
    <div id="skills" className="skill-set-container">
      <h1 className="name">
        <span>S</span>kills
      </h1>
      <h4>Here are list of my key skills...</h4>
      <ul className="skills">
        <SkillSet expertLevel="90" totalExpert="50" barHeight="30">
          <Java className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="90" totalExpert="50" barHeight="30">
          <SpringBoot className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="90" totalExpert="50" barHeight="30">
          <SpringReactive className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="70" totalExpert="50" barHeight="30">
          <JS className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="70" totalExpert="50" barHeight="30">
          <REACT className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="90" totalExpert="50" barHeight="30">
          <HTML className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="60" totalExpert="50" barHeight="30">
          <CSS className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="60" totalExpert="50" barHeight="30">
          <SASS className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="50" totalExpert="50" barHeight="30">
          <Kubernetes className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="50" totalExpert="50" barHeight="30">
          <Docker className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="30" totalExpert="50" barHeight="30">
          <Azure className="skill-image" />
        </SkillSet>

        <SkillSet expertLevel="10" totalExpert="50" barHeight="30">
          <Aws className="skill-image" />
        </SkillSet>
      </ul>
    </div>
  );
};
