import React from "react";
import Button from "react-bootstrap/Button";

export const ProjectSummary = ({ project }) => {
  const { duration, role, company, responsibilities, keyTechStack } = project;
  return (
    <div className="project__summary__container">
      <h4 className="text-data">{role}</h4>
      <div className="project__position">
        <Button className="btn-project text-data">{company}</Button>
        <Button className="btn-project text-data">{duration}</Button>
      </div>

      <ul className="project__summary_responsibility">
        {responsibilities &&
          responsibilities.map((responsiblity) => {
            return (
              <li className="responsibility" key={responsiblity}>
                <p className="text-data">{responsiblity}</p>
              </li>
            );
          })}
      </ul>
      <ul className="tech__stack">
        {keyTechStack &&
          keyTechStack.map((tech) => {
            return (
              <Button key={tech} className="text-data">
                {tech}
              </Button>
            );
          })}
      </ul>
    </div>
  );
};
