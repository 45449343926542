import React from "react";
import { Link } from "./Link.component";
import { ReactComponent as DevLogo } from "../assets/dev.svg";
import { ReactComponent as GithubLogo } from "../assets/github.svg";
import { ReactComponent as LinkedInLogo } from "../assets/linked-in.svg";

export const Social = () => {
  return (
    <div className="social-icons">
      <div className="social">
        <Link link="https://github.com/balasr21">
          <GithubLogo className="social-image" />
        </Link>
        <Link link="https://www.linkedin.com/in/balamurugan-radhakrishnan-01343717a/">
          <LinkedInLogo className="social-image" />
        </Link>
        <Link link="https://dev.to/balasr21">
          <DevLogo className="social-image" />
        </Link>
      </div>
    </div>
  );
};
