import React from "react";

export const Link = ({ link, children }) => {
  return (
    <a
      style={{ display: "table-cell" }}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
