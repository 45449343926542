import React, { useEffect, useState } from "react";
import { getRandomQuote } from "../utils/helper.js";
import axios from "../axios";
import Spinner from "./spinner/spinner.component";

export const HomePage = () => {
  const fetchURL =
    "https://us-central1-balasr-portfolio.cloudfunctions.net/getRandomQuote";
  const [quote, setQuote] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await axios.get(fetchURL);
      setQuote(getRandomQuote(response));
      setLoading(false);
      return response;
    }

    fetchData();
  }, []);

  return (
    <div id="home" className="home-container">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="home-intro">
            <div className="intro-text">
              <h2>Hello I'm</h2>
              <h1 className="name">
                <span aria-hidden="true">B</span>
                <span aria-hidden="true">a</span>
                <span aria-hidden="true">l</span>
                <span aria-hidden="true">a</span>
              </h1>
              <h3>
                I am a passionate web developer who have a high commitment to
                work and loves building stuffs
              </h3>
              <h4 className="random-quote">{quote}</h4>
            </div>
          </div>
          <div className="fade_bottom"></div>
        </>
      )}
    </div>
  );
};
