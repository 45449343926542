import React, { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BlogData } from "./BlogData.component";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { useParams } from "react-router";
import axios from "../axios";
import Spinner from "./spinner/spinner.component";
import { ReactComponent as Expand } from "../assets/expand.svg";
import { ReactComponent as Minimise } from "../assets/minimise.svg";

export const IndividualBlog = ({ setNavBarBackgroundDisplay }) => {
  const { blogId } = useParams();
  const fetchURL = `https://us-central1-balasr-portfolio.cloudfunctions.net/getPostsById?postId=${blogId}`;

  const [body, setBody] = useState("");
  const [coverMetaData, setCoverMetaData] = useState("");
  const [loading, setLoading] = useState(false);

  const [reSizeOptions, setReSizeOptions] = useState({
    active_id: null,
  });

  useEffect(() => {
    let coverData = {
      title: "",
      duration: "",
      coverImage: "",
      creationDate: "",
      shortDescription: "",
      category: [],
    };

    async function fetchData() {
      setNavBarBackgroundDisplay(true);
      setLoading(true);
      const response = await axios.get(fetchURL);

      setBody(response.data.fields.blogContent);

      coverData = {
        title: response.data.fields.title,
        duration: response.data.fields.duration,
        coverImage: `https:${response.data.fields.coverImage.fields.file.url}`,
        creationDate: response.data.fields.creationDate,
        shortDescription: response.data.fields.shortDescription,
        category: response.data.fields.category,
      };
      setCoverMetaData(coverData);
      setLoading(false);
    }
    fetchData();
  }, [blogId, fetchURL, setNavBarBackgroundDisplay]);

  function renderMedia(file) {
    const imageUrl = file.url;
    return (
      <div
        className="image__container"
        style={
          reSizeOptions.active_id === file.fileName
            ? {
                position: "fixed",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                backgroundColor: "#211717",
                zIndex: "11",
                overflow: "auto",
              }
            : null
        }
      >
        <div
          className="resize__container"
          onClick={() => {
            console.log("Clicked....", reSizeOptions);

            reSizeOptions.active_id === file.fileName
              ? setReSizeOptions({
                  active_id: null,
                })
              : setReSizeOptions({
                  active_id: file.fileName,
                });
            console.log("Clicked After....", reSizeOptions);
          }}
        >
          {reSizeOptions.active_id === file.fileName && (
            <Minimise className="resize__image max" style={{ fill: "white" }} />
          )}

          {reSizeOptions.active_id !== file.fileName && (
            <Expand className="resize__image min" style={{ fill: "white" }} />
          )}
        </div>
        <img
          key={file.fileName}
          className="img-embedded"
          src={imageUrl}
          alt="ImageNotFound"
        />
      </div>
    );
  }

  let options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        let file = node.data.target.fields.file;
        let jsx = renderMedia(file);
        return jsx;
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        let jsx = (
          <div
            className="code__container"
            style={
              reSizeOptions.active_id === node.data.target.fields.java
                ? {
                    position: "fixed",
                    top: "0",
                    left: "0",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#211717",
                    zIndex: "11",
                    overflow: "auto",
                  }
                : null
            }
          >
            <div
              className="resize__container"
              onClick={() => {
                console.log("Clicked....", reSizeOptions);

                reSizeOptions.active_id === node.data.target.fields.java
                  ? setReSizeOptions({
                      active_id: null,
                    })
                  : setReSizeOptions({
                      active_id: node.data.target.fields.java,
                    });
                console.log("Clicked After....", reSizeOptions);
              }}
            >
              {reSizeOptions.active_id === node.data.target.fields.java && (
                <Minimise
                  className="resize__image max"
                  style={{ fill: "black" }}
                />
              )}

              {reSizeOptions.active_id !== node.data.target.fields.java && (
                <Expand
                  className="resize__image min"
                  style={{ fill: "black" }}
                />
              )}
            </div>
            <pre className="language-java">
              <code>{node.data.target.fields.java}</code>
            </pre>
          </div>
        );
        return jsx;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <span>{children}</span>;
      },
    },
  };

  let blogDataComponent = documentToReactComponents(body, options);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <BlogData data={blogDataComponent} coverData={coverMetaData} />
      )}
      ;
    </>
  );
};
