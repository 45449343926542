import React from "react";
import {ReactComponent as GithubLogo} from "../assets/github.svg";
import {ReactComponent as AwsCertifiedLogo} from "../assets/awscertified.svg";
import {ReactComponent as BaeldungLogo} from "../assets/baeldung.svg";
import {ReactComponent as UnleashLogo} from "../assets/unleash.svg";
import {ReactComponent as LinkedInLogo} from "../assets/linked-in.svg";
import {HiOutlineMail} from "react-icons/hi";
import {GoLocation} from "react-icons/go";
import {BiPhoneCall} from "react-icons/bi";
import {AiFillChrome} from "react-icons/ai";

export const ContactIcons = ({contacts}) => {
    return contacts.map((contact) => {
        if (contact.item === "git") {
            return (
                <div className="profile__contact" key="git">
                    <GithubLogo
                        onClick={(event) => (window.location.href = contacts.data)}
                    />
                    <a className="external" href={contact.data}>{contact.displayName}</a>
                </div>
            );
        } else if (contact.item === "linkedin") {
            return (
                <div className="profile__contact" key="linkedin">
                    <LinkedInLogo
                        onClick={(event) => (window.location.href = contacts.data)}
                    />
                    <a className="external" href={contact.data}>{contact.displayName}</a>
                </div>
            );
        } else if (contact.item === "mail") {
            return (
                <div className="profile__contact" key="mail">
                    <HiOutlineMail/>
                    <span className="contact__data">
            <a href={`mailto:${contact.data}`}>{contact.displayName}</a>
          </span>
                </div>
            );
        } else if (contact.item === "location") {
            return (
                <div className="profile__contact" key="location">
                    <GoLocation/>
                    <span className="contact__data">
            <a href={contact.data}>{contact.displayName}</a>
          </span>
                </div>
            );
        } else if (contact.item === "phone") {
            return (
                <div className="profile__contact" key="phone">
                    <BiPhoneCall/>
                    <span className="contact__data">
            <a href={contact.data}>{contact.displayName}</a>
          </span>
                </div>
            );
        } else if (contact.item === "website") {
            return (
                <div className="profile__contact" key="website">
                    <AiFillChrome
                        onClick={(event) => (window.location.href = contacts.data)}
                    />
                    <a className="external" href={contact.data}>{contact.displayName}</a>
                </div>
            );
        } else if (contact.item === "certification") {
            return (
                <div className="profile__contact" key="certification" onClick={() => (window.location.href = contacts.data)}>
                    <AwsCertifiedLogo
                        onClick={(event) => (window.location.href = contacts.data)}
                    />
                    <a className="external" href={contact.data}>{contact.displayName}</a>
                </div>
            );
        } else if (contact.item === "baeldung") {
            return (
                <div className="profile__contact" key="baeldung" onClick={() => (window.location.href = contacts.data)}>
                    <BaeldungLogo
                        onClick={() => (window.location.href = contacts.data)}>
                    </BaeldungLogo>
                    <a className="external" href={contact.data}>{contact.displayName}</a>
                </div>
            );
        } else if (contact.item === "unleash") {
            return (
                <div className="profile__contact" key="unleash">
                    <UnleashLogo
                        onClick={(event) => (window.location.href = contacts.data)}
                    />
                    <a className="external" href={contact.data}>{contact.displayName}</a>
                </div>
            );
        }
        return null;
    });
};
