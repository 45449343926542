import React from "react";
import { useHistory } from "react-router";
import moment from "moment";

export const BlogSummary = ({
  name,
  shortSummary,
  duration,
  creationDate,
  category,
  body,
  blogId,
}) => {
  const history = useHistory();
  let summaryCategory = [...category];

  if (category.length > 2) {
    summaryCategory.length = 2;
    summaryCategory.splice(2, 0, "...");
  }

  return (
    <>
      {
        <div
          className="blog-summary-container"
          onClick={() => history.push(`/blog/${blogId}`)}
        >
          <p className="blog-topic-name">{name}</p>
          <p className="blog-top-short-desc">{shortSummary}</p>

          <div className="blog-metadata-all">
            <table className="blog-metadata-sub1">
              <thead>
                <tr key="duration">
                  <th>Duration(mins)</th>
                </tr>
              </thead>
              <tbody>
                <tr key="duration-value">
                  <td>{duration}</td>
                </tr>
              </tbody>
            </table>

            <table className="blog-metadata-sub2">
              <thead>
                <tr key="creationDate">
                  <th>PublishedDate</th>
                </tr>
              </thead>
              <tbody>
                <tr key="creationDate-value">
                  <td>{moment(creationDate).format("DD MMM YYYY")}</td>
                </tr>
              </tbody>
            </table>

            <table className="blog-metadata-sub3">
              <thead>
                <tr key="category">
                  <th>Category</th>
                </tr>
              </thead>

              <tbody>
                {summaryCategory.map((coll) => (
                  <tr key={coll}>
                    <td>{coll}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* <ul className="blog-metadata">
            <li className="blog-metadata-item">{`Duration : ${duration}`}</li>
            <li className="blog-metadata-item">{`Creation Date : ${creationDate}`}</li>
            {category.map((coll) => (
              <li className="blog-metadata-item">{coll}</li>
            ))}
          </ul> */}
        </div>
      }
    </>
  );
};
