import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { FaTools } from "react-icons/fa";
import { SiAboutDotMe } from "react-icons/si";
import { FiLayers } from "react-icons/fi";
import { useHistory } from "react-router";
import { ReactComponent as Blog } from "../assets/blog.svg";
import { ReactComponent as ResumeIcon } from "../assets/resume.svg";

export const Navbar = ({
  show,
  setShow,
  setResume,
  navBarBackgroundDisplay,
  setNavBarBackgroundDisplay,
}) => {
  const history = useHistory();

  useEffect(() => {
    setResume(false);
    document.body.style.background = "#211717";
  }, [setResume]);

  return (
    <div className="navbar-container">
      <div
        className={`navbar-items ${
          navBarBackgroundDisplay && "nav_background"
        }`}
      >
        <ul className="nav-items">
          <li className="nav-item" activeclass="active">
            <Link
              to="/"
              onClick={() => {
                history.push("/");
                setNavBarBackgroundDisplay(false);
              }}
            >
              <div className="nav-item-container">
                <AiOutlineHome className="nav-item-icon" />
                <p className="nav-item-icon-name">Home</p>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="about"
              onClick={() => {
                history.push("/about");
                setNavBarBackgroundDisplay(true);
                !show && setShow(true);
              }}
            >
              <div className="nav-item-container">
                <SiAboutDotMe className="nav-item-icon" />
                <p className="nav-item-icon-name">About</p>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="skills"
              onClick={() => {
                history.push("/skills");
                setNavBarBackgroundDisplay(true);
                !show && setShow(true);
              }}
            >
              <div className="nav-item-container">
                <FaTools className="nav-item-icon" />
                <p className="nav-item-icon-name">Skills</p>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="projects"
              onClick={() => {
                history.push("/projects");
                setNavBarBackgroundDisplay(true);
                !show && setShow(true);
              }}
            >
              <div className="nav-item-container">
                <FiLayers className="nav-item-icon" />
                <p className="nav-item-icon-name">Projects</p>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="blog"
              onClick={() => {
                history.push("/blog");
                setNavBarBackgroundDisplay(true);
                !show && setShow(true);
              }}
            >
              <div className="nav-item-container">
                <Blog className="nav-item-icon" />
                <p className="nav-item-icon-name">Blog</p>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="my-resume"
              onClick={() => {
                history.push("/my-resume");
                setNavBarBackgroundDisplay(true);
                !show && setShow(true);
              }}
            >
              <div className="nav-item-container">
                <ResumeIcon className="nav-item-icon" />
                <p className="nav-item-icon-name">MyResume</p>
                <hr className="nav-item-icon-ruler" />
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
