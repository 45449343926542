import React from "react";

const Spinner = () => (
  <div className="frame">
    <div className="spinner">
      <div className="spinner-container">
        <div className="spinner-item">B</div>
        <div className="spinner-item">A</div>
        <div className="spinner-item">L</div>
        <div className="spinner-item">A</div>
      </div>
    </div>
  </div>
);

export default Spinner;
