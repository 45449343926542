import React, { useState, useEffect } from "react";
import { getProject } from "../utils/helper";

export const IndividualProject = ({ ...props }) => {
  const [clicked, setClicked] = useState(false);
  const [projectSummary, setProjectSummary] = useState("");
  // ToDo pass it as image file names and use it to render component
  const [techStack, setTechStack] = useState([]);
  // const [projectDisplay, setProjectDisplay] = useState(props.front);
  // const [isFrontPageDisplayed, setIsFrontPageDisplayed] = useState(true);

  useEffect(() => {
    let project = getProject(props.projectDesc);
    setProjectSummary(project.summary);
    setTechStack(project.techStack);

    // Todo Find a better way to achieve this. Current way renders component every second
    // const displayComponent = setInterval(() => {
    //   if (isFrontPageDisplayed) {
    //     setProjectDisplay(props.back);
    //     setIsFrontPageDisplayed(false);
    //   } else {
    //     setProjectDisplay(props.front);
    //     setIsFrontPageDisplayed(true);
    //   }
    // }, 2000);
    // return () => {
    //   clearInterval(displayComponent);
    // };
  }, [props.back, props.front, props.projectDesc]);

  return (
    <>
      {/*Project card component*/}
      {!clicked && (
        <li className="project-item" onClick={() => setClicked(true)}>
          {props.front}
        </li>
      )}
      {/*Project Overview component*/}

      {clicked && (
        <li
          className="project-overview"
          onMouseLeave={() => setClicked(false)}
          onClick={() => setClicked(false)}
        >
          <div className="project-overview-container">
            <span className="project-overview-image">{props.overview}</span>
            <div className="project-overview-summary">
              <p className="project-summary">{projectSummary}</p>
              <h4 className="project-summary">
                Key technical stack used in this project:{" "}
              </h4>
              <ul className="project-tech-stack">
                {techStack &&
                  techStack.map((stack) => (
                    <li key={stack} className="tech-stack">
                      {stack}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </li>
      )}
    </>
  );
};
