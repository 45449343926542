import React, { createContext, useContext } from "react";
import { usePostById } from "../hooks/index";

export const PostContext = createContext();
export const PostProvider = ({ children }) => {
  const post = usePostById();
  return <PostContext.Provider value={post}>{children}</PostContext.Provider>;
};

export const usePostValue = () => useContext(PostContext);
