import { useEffect, useState } from "react";
import axios from "../axios";

const fetchURL =
  "https://us-central1-balasr-portfolio.cloudfunctions.net/getAllPosts";

export const usePosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(fetchURL);
      setPosts(response);
    }
    fetchData();
  }, []);

  return { posts };
};

export const usePostById = (postId) => {
  useEffect(() => {
    let unsubscribe =
      postId &&
      getClient
        .getEntry({ postId })
        .then((entry) =>
          console.log("Received response for postId", postId, entry)
        )
        .catch(console.error);

    return () => unsubscribe();
  }, [postId]);

  return {};
};

export const useLoading = () => {
  const [loading, setLoading] = useState(false);

  return { loading, setLoading };
};

const getClient = require("contentful").createClient({
  space: "abcd",
  environment: "master",
  accessToken: "abcd",
});
