import React, { createContext, useContext } from "react";
import { usePosts } from "../hooks/index";

export const PostsContext = createContext();
export const PostsProvider = ({ children }) => {
  const posts = usePosts();
  return (
    <PostsContext.Provider value={posts}>{children}</PostsContext.Provider>
  );
};

export const usePostsValue = () => useContext(PostsContext);
