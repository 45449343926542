import React from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";

export const BlogData = ({ data, coverData }) => {
  return (
    <div className="blog_content_container">
      <div className="blog_meta">
        <h2 className="blog_title">{coverData.title}</h2>
        <img
          className="cover_image"
          src={`${coverData.coverImage}`}
          alt="cover_image"
        ></img>
        <div className="blog_meta_info">
          <div className="blog_meta_items">
            <span>{`PublishedDate : ${moment(coverData.creationDate).format(
              "DD MMM YYYY"
            )}`}</span>
            <span>{`Duration : ${coverData.duration}`}</span>
          </div>
          <div className="blog_meta_category">
            {coverData.category &&
              coverData.category.map((category) => {
                return (
                  <Button
                    key={category}
                    variant="outline-primary"
                    className="category-links-button"
                  >
                    {category}
                  </Button>
                );
              })}
          </div>
        </div>
      </div>

      <div className="blog_content">
        <hr />
        {data}
        {/* {ReactHtmlParser(data)} */}
      </div>
    </div>
  );
};
