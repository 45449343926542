import React, { useEffect, useState } from "react";

export const Expert = ({ expertLevel, totalExpert, barHeight }) => {
  const [value, setValue] = useState(0);

  //let { expertLevel } = props;

  useEffect(() => {
    setValue((expertLevel * totalExpert) / 100);
  }, [expertLevel, totalExpert]);

  return (
    <div
      className="progress-bar"
      style={{ width: `${totalExpert}vw`, height: `${barHeight}px` }}
    >
      <div
        className="expert-level"
        style={{ width: `${value}vw`, height: `${barHeight}px` }}
      >
        <p className="expert-level-value">{expertLevel && ""}</p>
      </div>
    </div>
  );
};
