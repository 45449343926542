import React from "react";

export const Timeline = ({ isFirst }) => {
  return (
    <div className="timeline__container">
      <div className="circle"></div>
      <div className="vertical"></div>
    </div>
  );
};
