import React, { useEffect } from "react";
import { useLoadingValue } from "../context/index";
import Spinner from "../components/spinner/spinner.component";

export const About = () => {
  const { loading, setLoading } = useLoadingValue();

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div id="about" className="about">
        <h1 className="name">
          <span>A</span>bout me
        </h1>
        <h2 className="about-summary">
          I am a Software developer in London. I am highly committed and
          passionate about my job and love to build stuffs.
        </h2>

        <h4 className="about-desc">
          Coding always excites me ever since the beginning of my career and
          solving a problem gives a high level of satisfaction.I started my
          software career as a tester gained experience in various phases of
          testing.Eventually moved to a developer role because of my interest
          and have been building stuffs ever since then, Having started my
          career as a tester, it helps provide quality deliverable when moved
          into development and more importantly i love what i am doing
        </h4>

        <h4 className="why">
          So why did i created this website ? I love to code and enjoy the
          process of learning. No good knowledge is worth if not shared! So I
          aim to
          <ul className="site-reasons">
            <li className="site-reason">
              <h4>Showcase my skills</h4>
            </li>
            <li className="site-reason">
              <h4>Share my knowledge</h4>
            </li>
          </ul>
        </h4>
      </div>
    );
  }
};
