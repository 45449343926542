import React, { useState, useEffect } from "react";
import { BlogSummary } from "./BlogSummary.component";
import { getBlogByCollection } from "../utils/helper";

export const AllBlogs = ({ currentCollection, posts, show, setShow }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(getBlogByCollection(currentCollection, posts));
  }, [currentCollection, posts]);

  return (
    <div className="blogs">
      <ul className="all-blogs">
        {blogs.map((blog) => (
          <li
            key={blog.sys.id}
            className="blog"
            onClick={() => {
              setShow(false);
            }}
          >
            <BlogSummary
              name={blog.fields.title}
              shortSummary={blog.fields.shortDescription}
              duration={blog.fields.duration}
              creationDate={blog.fields.creationDate}
              category={blog.fields.category}
              body={blog.fields.blogContent}
              blogId={blog.sys.id}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
